import React, { useEffect } from 'react'
import Navbar from '../navbar/Navbar'
import inter from '../../asset/interface.gif'
import mobile from '../../asset/mobile-apps.gif'
import setting from '../../asset/settings.gif'
import html from '../../asset/html.gif'
import   './pages.css'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ProjectSlider from './ProjectSlider'
import Footer from './Footer'
import Tesimoial from './Tesimoial'
import Lottie from 'react-lottie'
import heroimg from '../../asset/herosection.json'
import LogoGrid from '../Logo/LogoGrid'
import Technology from './Technology';

import img1 from '../images/img-about.png';
import img2 from '../images/Raj Rani Spices.png';
import img3 from '../images/Bright.jpg';
import img4 from '../images/kuzztomzoo.png';

import bright_logo from '../images/img-about.png';


const Home = () => {
  
    useEffect(() => {
      setTimeout(() => {
        AOS.init({ duration: 1000, offset: 100, once:true });
      }, []); // Simulating a 2-second loading time
    }, []);

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: heroimg,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    

  return (
    <>

      <div className="herosection">
      <Navbar/>
      {/* Herosection */}

      <div className="containers">
        <div data-aos="fade-right" className="main1">
          <h1>Your satisfaction is our priority. We exceed your needs.</h1>
          <p className='animated-text'>And that's why Beliance Web is here for your satisfation </p>
          <Link to={"/Service"}  className="button">View More</Link>
        </div>
        <div className="main2">
        <Lottie style={{width:'90%' , height:'auto'}}  options={defaultOptions} height={300} width={400} loop='true' isClickToPauseDisabled={true}/>
        </div>
      </div>
      </div>

      {/* cardsection */}
      <div className="fixed "></div>
      <section className="card  bg-gray-100 px-12 py-12">
      <div className="container mx-auto px-2  sm:px-4">
      <div className="heading text-center mb-8">
        <h1 className="text-5xl font-bold mb-2 expect">What you can Expect from Beliance?</h1>
        <p className="text-2xl my-3 text-black-400">
Delivering unparalleled technology solutions through meticulously crafted,<br/>hand-coded strategies to unravel intricate business challenges.</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div  data-aos="fade-up" className="bg-white p-6 rounded-lg shadow-lg text-center">
          <img className="w-24 mx-auto mb-4" src={inter} alt="Web Interface" />
          <p className="text-lg font-semibold mb-2">Web Development</p>
        </div>
        <div data-aos="fade-up" className="bg-white p-6 rounded-lg shadow-lg text-center">
          <img className="w-24 mx-auto mb-4" src={mobile} alt="Mobile Development" />
          <p className="text-lg font-semibold mb-2">App Development</p>
        </div>
        <div data-aos="fade-up" className="bg-white p-6 rounded-lg shadow-lg text-center">
          <img className="w-24 mx-auto mb-4" src={setting} alt="Web Setting" />
          <p className="text-lg font-semibold mb-2">ERP Solution</p>
        </div>
        <div data-aos="fade-up" className="bg-white p-6 rounded-lg shadow-lg text-center">
          <img className="w-24 mx-auto mb-4" src={html} alt="Web-App Development" />
          <p className="text-lg font-semibold mb-2">UI/UX Design</p>
        </div>
      </div>
      <div data-aos="fade-up" className="flex justify-center my-4">
        <Link to={"/Service"}  className="button service-button  linkservice flex justify-center items-center text-center text-white bg-blue-500 py-2 px-4 rounded-lg hover:bg-red-500 ">See More Service</Link>
        </div>
      </div>
      </section>


      {/* Project */}

      {/* <section className="card bg-gray-100 px-4 sm:px-8 md:px-12 py-8 sm:py-12 flex justify-center items-center">
      <div className="container mx-auto">
      <div className="heading text-center mb-8">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-2" style={{marginRight:'20px'}}>Our Project</h1>
      </div>

      <ProjectSlider/>
      <div data-aos="fade-up" className="flex justify-center my-4">
        <Link to={"/project"}  className="button linkservice flex justify-center items-center text-center text-white bg-blue-500 py-2 px-4 rounded-lg hover:bg-red-500 ">See More Project</Link>
        </div>
      </div>
      </section> */}


      {/*Perfect*/}
      {/* <section className="card bg-gray-100 px-12 py-12">
      <div className="container mx-auto px-2  sm:px-4">
        
      <div data-aos="fade-up" className="grid grid-cols-1 md:grid-cols-2 gap-6 justify-center items-center px-8 py-12 md:py-24 text-center">
      <div className="max-w-xs md:max-w-lg">
      <p className="text-sm md:text-lg text-gray-600 mb-4">OFFER AN IDEAL RESOLUTION</p>
      <h1 className="text-3xl md:text-5xl font-bold mb-6">Explore our adaptable client plans designed to cater to your unique needs and preferences.</h1>
      </div>
      <div data-aos="fade-up" className="max-w-xs md:max-w-lg">
      <p className="text-sm md:text-lg text-gray-700 mb-4">We perform full analysis of the client's website and collect information about the competitors to formulate a proper strategy.</p>
      <p className="text-sm md:text-lg text-gray-700 mb-4">There is no one universal solution in online marketing, that's why every time we create a unique strategy tailored to the customer's needs.</p>
      </div>
      </div>
      </div>
      </section> */}

      {/* <Tesimoial/> */}
      <div className='clients_page' style={{backgroundColor:'white'}}>
    <div data-aos="fade-up">
        <h1 className="text-5xl mb-3 text-center">Our Recent Clients</h1>
<br />

    <section className="clients">
        <div className="client">
          <a href='https://brightenergy.co.in/' target='_blank'>
            <img src={bright_logo} className='bright_logo' alt=""/>
            </a>
        </div>
        <hr/>
        <div class="client">
          <a href='https://rajranimasala.com/' target='_blank'>
            <img src={img2} className='bright_logo2' alt=""/>
            </a>
        </div>
        <hr/>
        <div class="client">
          <a href='https://kuzztomzoo.com/' target='_blank'>
            <img src={img4} className='bright_logo2' alt=""/>
            </a>
        </div>
        {/* <hr/> */}

    </section>
    </div>
    </div>




        <Technology/>


      <Footer/> 


      {/* subscribe */}

    
  </>
  )
}

export default Home