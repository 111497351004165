import React from 'react'
import './footer.css'
import img1 from '../images/beliance-web.svg'
import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

const Footer = () => {
  return (
    <>
<footer className="new_footer_area bg_color">
  <div className="new_footer_top">
    <div className="container-footer">
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div
            className="f_widget company_widget wow fadeInLeft"
            data-wow-delay="0.2s"
            style={{
              visibility: "visible",
              animationDelay: "0.2s",
              animationName: "fadeInLeft"
            }}
          >
            <img src={img1} alt='' className="f-title f_600 t_color f_size_18"></img>
            <p>Empowering your business with innovative IT solutions for a digital future.</p>
            {/* <form
              action="/"
              className="f_subscribe_two mailchimp"
              method="post"
              noValidate="true"
              _lpchecked={1}
            >
              <input
                type="text"
                name="EMAIL"
                className="form-control memail"
                placeholder="Email"
              />
              <button className="btn btn_get btn_get_two" type="submit">
                Subscribe
              </button>
              <p className="mchimp-errmessage" style={{ display: "none" }} />
              <p className="mchimp-sucmessage" style={{ display: "none" }} />
            </form> */}
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div
            className="f_widget about-widget pl_70 wow fadeInLeft"
            data-wow-delay="0.4s"
            style={{
              visibility: "visible",
              animationDelay: "0.4s",
              animationName: "fadeInLeft"
            }}
          >
            <h3 className="f-title f_600 t_color f_size_18">USEFUL LINK</h3>
            <ul className="list-unstyled f_list">
              <li>
                <a href="/">HOME</a>
              </li>
              <li>
                <a href="/service">SERVICE</a>
              </li>
              <li>
                <a href="/about">ABOUT US</a>
              </li>
              <li>
                <a href="/products">PRODUCTS</a> 
              </li>
              <li>
                <a href="/contact">CONTACT US</a>
              </li>
              {/* <li>
                <a href="/">My tasks</a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div
            className="f_widget about-widget pl_70 wow fadeInLeft"
            data-wow-delay="0.6s"
            style={{
              visibility: "visible",
              animationDelay: "0.6s",
              animationName: "fadeInLeft"
            }}
          >
            <h3 className="f-title f_600 t_color f_size_18">Services</h3>
            <ul className="list-unstyled f_list">
              <li>
                <a href="/service">Web Development</a>
              </li>
              <li>
                <a href="/service">Software Development</a>
              </li>
              <li>
                <a href="/service">App Development</a>
              </li>
              <li>
                <a href="/service">ERP Solution</a>
              </li>
              <li>
                <a href="/service">Digital Marketing</a>
              </li>
              <li>
                <a href="/service">UI/UX Design</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div
            className="f_widget social-widget pl_70 wow fadeInLeft"
            data-wow-delay="0.8s"
            style={{
              visibility: "visible",
              animationDelay: "0.8s",
              animationName: "fadeInLeft"
            }}
          >
            <h3 className="f-title  f_600 t_color f_size_18">Social</h3>
            <div className="f_social_icon">
              <a href="https://www.linkedin.com/company/beliance-web/" className="fab fa-linkedin" target="_blank"/>
              <a href="https://x.com/belianceweb" className="fa-brands fa-x-twitter" target="_blank"/>
              {/* <a href="/"   className="fab fa-facebook" target="_blank" /> */}
              <a href="https://www.instagram.com/belianceweb/?next=%2F"  className="fab fa-instagram" target="_blank"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer_bg">
      <div className="footer_bg_one" />
      <div className="footer_bg_two" />
    </div>
  </div>
  <div className="footer_bottom">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-sm-7">
          <p className="mb-0 f_400">
            © Beliance Web 2024 All rights reserved.
          </p>
        </div>
        <div className="col-lg-6 col-sm-5 text-right">
          {/* <p>
            Made with <i className="icon_heart" /> in{" "}
            <a href="http://cakecounter.com" target="blank">
              CakeCounter
            </a>
          </p> */}
        </div>
      </div>
    </div>
  </div>
</footer>

    </>
  )
}

export default Footer