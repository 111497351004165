import React, { useEffect } from "react";
import Navbar from "../navbar/Navbar";
import "./pages.css";
import "aos/dist/aos.css";
import AOS from "aos";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import webdev from "../../asset/Webdev.json";
import uxui from "../../asset/uiux.json";
import sbm from "../../asset/sbm- logo.png";

import appdev from "../../asset/appdeveloper2.json";
// import software_development from '../../asset/software_development.json'
import software from "../../asset/Webdeveloper.json";
import digital_marketing from "../../asset/digital_marketing.json";
import erp_solution from "../../asset/erp_solution.json";
import Aos from "aos";

const Service = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, offset: 100 });
  }, []);

  const features = [
    {
      icon: "📄", // Emoji icons for simplicity; replace with SVG if needed
      title: "My bills",
      description:
        "Generate maintenance bills & check bills history in a few taps."
    },
    {
      icon: "📢",
      title: "Announcement",
      description:
        "Use the app's digital noticeboard to make announcements and keep everyone up-to-date."
    },
    {
      icon: "🔍",
      title: "Search",
      description:
        "Search members, vehicle info and more rights within the app."
    },
    {
      icon: "💰",
      title: "Expense tracker",
      description: "Manage bills, track expenses, check balance dues on-the-go."
    },
    {
      icon: "📊",
      title: "Voting",
      description:
        "Take decisions easily by posting questions and members participate by giving votes."
    },
    {
      icon: "🏢",
      title: "Facility",
      description:
        "Check date availability and book the common facility like parking area, community hall."
    },
    {
      icon: "📞",
      title: "Complaints",
      description:
        "Send any complaints related to your building directly to Admin."
    },
    {
      icon: "📂",
      title: "Emergency directory",
      description:
        "Upload emergency contacts for enabling members to contact without bothering the society management."
    }
  ];
  Aos.init({
    once: true
  });

  return (
    <>
      <Navbar />
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5 }}
      >
        <div data-aos="fade-down" className="service-main ">
          <div className="servicegrid product-Onzup">
            <h1>Product Details</h1>
            <p>
              Welcome to Beliance Web! Elevate your online presence with our
              expert team and tailored solutions.
            </p>
          </div>
        </div>

        {/* Web */}
        <div className="margin"></div>

        {/* Web */}
        <div className="margin"></div>
        <div className="web-container grid grid-cols-1 my-10  ">
          <div data-aos="fade-in" className="img-web sbm-container ">
            <img className="w-88 sbm-img" src={sbm} alt="Web Interface" />
          </div>
          {/* <Lottie style={{width:'90%' , height:'auto'}}  options={defaultOptions} loop='true' isClickToPauseDisabled={true}/> */}
          <div data-aos="fade-up" className="web ml-3">
            <h1>Smart Building</h1>
            <h1> Maintenance Solutions</h1>
            <p className="mt-2">
              In last decade IT industry has grown so much but not in all the
              fields. We have seen that not a single IT solution provides proper
              society management and security tools. Society admins & committee
              were facing many problems like generating every month maintenance
              bills, collecting cheques, keeping track of who paid and who
              didn't, security on the gate, keeping the track of all the
              visitors.
            </p>
            <div className="list-icon grid grid-cols-2 gap-1 mt-4 ">
                <div className="item item1 flex items-center item-container"><i className="fas fa-circle-check mr-2"></i>Streamlined Maintenance Management</div>
                <div className="item item2 flex items-center item-container"><i className="fas fa-circle-check mr-2"></i>Automated Billing and Invoicing</div>
                <div className="item item3 flex items-center item-container"><i className="fas fa-circle-check mr-2"></i>Expense Tracking & Reporting</div>
                <div className="item item4 flex items-center item-container" ><i className="fas fa-circle-check mr-2"></i>Real-Time Communication</div>
                <div className="item item5 flex items-center item-container"><i className="fas fa-circle-check mr-2"></i>24/7 Access via Mobile App</div>
                <div className="item item6 flex items-center item-container"><i className="fas fa-circle-check mr-2"></i>Search & Interact with Members</div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center space-y-4 mt-4">
  <div className="text-center font-bold text-4xl w-4/5">
    Why Building Maintenance?
  </div>
  <div className="text-center text-base w-3/5 my-2 mx-4 ">
    Building maintenance app streamlines management and inter-society communication and lets you generate bills, create a detailed record of society expenses, search & interact with RWA members, make an announcement & be a better administrator!
  </div>
</div>

              <div className="youtube-video mt-4" data-aos="zoom-in">
          {/* <iframe
            width="50%"
            height="315"
            src="https://youtu.be/EGzw4HjGc3Y?si=UUDl5fNfZS-Gwqf_"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe> */}
          <iframe 
          className="youtube-iframe"
            width="540"
            height="315"
            src="https://www.youtube.com/embed/EGzw4HjGc3Y?si=jsYQJ5oXmbag-x_V"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>

        <div className="feature-container">
      <h2 className="feature-title">Our Main Features</h2>
      <div className="feature-grid">
        {features.map((feature, index) => (
          <div className="feature-card" key={index}>
            <div className="feature-icon">{feature.icon}</div>
            <h3 className="feature-heading">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>

        {/* <div className="idea">
          <div className="idea-bulb">
            <div className="bulb">
              <i className="fa-regular fa-lightbulb"></i>
            </div>
            <div className="content">
              <h1>Do You have an idea?</h1>
              <p>
              Transform your building management with innovative solutions. 
              Discover how our Smart Building Maintenance Solutions can simplify your operations and enhance efficiency.
              </p>
              <Link className="button" to={"/contact"}>
                Let's talk
              </Link>
            </div>
          </div>
        </div> */}

<div className="idea py-8 px-4">
  <div className="idea-bulb mx-auto max-w-4xl text-center">
    <div className="bulb mx-auto mb-4">
      <i className="fa-regular fa-lightbulb text-4xl sm:text-5xl text-yellow-500"></i>
    </div>
    <div className="content">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4">
        Do You Have an Idea for Building Maintenance?
      </h1>
      <p className="text-base sm:text-lg md:text-xl mb-6">
        Transform your building management with innovative solutions. Discover how our{" "}
        <span className="font-semibold">Smart Building Maintenance Solutions</span> can simplify your operations and enhance efficiency.
      </p>
      <div className="flex flex-col sm:flex-row justify-center gap-4">
        <Link
          className="button bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          to="https://www.smartbuildingmaintenance.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </Link>
        <Link
          className="button bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          to="/contact"
        >
          Contact Us
        </Link>
      </div>
    </div>
  </div>
</div>



        <Footer />
      </motion.div>
    </>
  );
};

export default Service;
