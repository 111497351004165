import React, { useEffect } from "react";
import Navbar from "../navbar/Navbar";
import "./pages.css";
import "aos/dist/aos.css";
import AOS from "aos";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import webdev from "../../asset/Webdev.json";
import uxui from "../../asset/uiux.json";
import sbm from "../../asset/sbm- logo.png";
import onzup from "../../asset/Onzup.jpg";
import appdev from "../../asset/appdeveloper2.json";
// import software_development from '../../asset/software_development.json'
import software from "../../asset/Webdeveloper.json";
import digital_marketing from "../../asset/digital_marketing.json";
import erp_solution from "../../asset/erp_solution.json";
import Aos from "aos";
// import Lottie from 'react-lottie';

const Service = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, offset: 100 });
  }, []);
  Aos.init({
    once: true
  });

  return (
    <>
      <Navbar />
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5 }}
      >
        <div data-aos="fade-down" className="product-main product-margin">
          <div className="productgrid">
            <h1>Our Products</h1>
            <p>
              Explore our software solutions designed to boost efficiency and
              drive business growth with tailored, user-friendly tools.
            </p>
          </div>
        </div>

        {/* Product  */}
        <div className="product-container mt-5 ">
          <div className="product-card">
            <div className="image-container">
              <img src={sbm} alt="WhatsApp Marketing Software" />
            </div>
            <h3>Smart Building</h3>
            <h3>Maintenance Solutions</h3>
            <p className="mt-4">
            Building maintenance app streamlines management and inter-society communication and lets you generate bills, create a detailed record of society expenses, search & interact with RWA members, make an announcement & be a better administrator!
            </p>
            <Link className="button" to={"/sbm_products"}>
              Read More
            </Link>
          </div>

          <div className="product-card ml-5">
            <div className="image-container">
              <img src={onzup} alt="WhatsApp Marketing Software" />
            </div>
            <h3>ONZUP</h3>
            <h3></h3>
            <p className="mt-5">
            Onzup is an online office management solution for Chartered Accountants, Company Secretaries, Tax Consultants, and Accountants. It offers features like client management, document storage, task assignment, reminders, and reporting. Developed by Sphere Rays Group, it streamlines operations and enhances productivity.
           </p>
            <Link className="button" to={"/onzup_products"}>
              Read More
            </Link>
          </div>
        </div>
        {/* Web */}
        {/* <div className="margin"></div>
        <div className="web-container grid grid-cols-1 ">
          <div data-aos="fade-in" className="img-web">
            <img className="w-80" src={sbm} alt="Web Interface" />

          </div> */}
        {/* <Lottie style={{width:'90%' , height:'auto'}}  options={defaultOptions} loop='true' isClickToPauseDisabled={true}/> */}
        {/* <div data-aos="fade-up" className="web ml-4">
            <h1>Smart Building</h1>
            <h1> Maintenance Solutions</h1>
            <p className="mt-2">
              In last decade IT industry has grown so much but not in all the
              fields. We have seen that not a single IT solution provides proper
              society management and security tools. Society admins & committee
              were facing many problems like generating every month maintenance
              bills, collecting cheques, keeping track of who paid and who
              didn't, security on the gate, keeping the track of all the
              visitors.
            </p>
          </div>
        </div> */}

        {/* <div className="youtube-video mt-4" data-aos="zoom-in">
          <iframe
            width="50%"
            height="315"
            src="https://www.youtube.com/embed/YOUR_VIDEO_ID"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div> */}

        <div className="idea">
          <div className="idea-bulb">
            <div className="bulb">
              <i className="fa-regular fa-lightbulb"></i>
            </div>
            <div className="content">
              <h1>Do You have an idea?</h1>
              <p>
                We can help you with Estimation, Prototype, Minimum Viable
                Product, Complete Solution for new and existing product.
              </p>
              <Link className="button" to={"/contact"}>
                Let's talk
              </Link>
            </div>
          </div>
        </div>

        <Footer />
      </motion.div>
    </>
  );
};

export default Service;
