import React, { useState, useEffect } from "react";
import Navbar from "../navbar/Navbar";
import "./pages.css";
import "aos/dist/aos.css";
import AOS from "aos";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import webdev from "../../asset/Webdev.json";
import uxui from "../../asset/uiux.json";
import sbm from "../../asset/sbm- logo.png";
import onzup from "../../asset/Onzup.jpg";

import appdev from "../../asset/appdeveloper2.json";
// import software_development from '../../asset/software_development.json'
import software from "../../asset/Webdeveloper.json";
import digital_marketing from "../../asset/digital_marketing.json";
import erp_solution from "../../asset/erp_solution.json";
import Aos from "aos";
// import Modal from "../pages/modal";

const Service = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
  
    useEffect(() => {
      AOS.init({ duration: 1000, offset: 100 });
      const timer = setTimeout(() => setIsModalOpen(false), 10000);
      return () => clearTimeout(timer); // Cleanup timeout
    }, []);

    const features = [
      {
        icon: "📄",
        title: "Client Login",
        description:
          "Allow clients to securely log in and access their society management details via Onzup."
      },
      {
        icon: "👨‍💼",
        title: "Staff Login",
        description:
          "Staff can securely log in to manage administrative tasks, announcements, and member details."
      },
      {
        icon: "⏰",
        title: "Reminders",
        description:
          "Set automated reminders for bills, meetings, and tasks to keep everyone informed and on track."
      },
      {
        icon: "🔄",
        title: "Auto Assign Tasks",
        description:
          "Automatically assign tasks to staff members based on predefined rules or availability."
      },
      {
        icon: "📂",
        title: "Document IN/OUT",
        description:
          "Track and manage documents going in and out of the society office for proper record-keeping."
      },
      {
        icon: "📧",
        title: "SMS/Email Notifications",
        description:
          "Receive timely updates on important events, announcements, or reminders via SMS or email notifications."
      },
      {
        icon: "📊",
        title: "Lot of Analytical Reports",
        description:
          "Generate comprehensive analytical reports to gain insights into society management, finances, and usage."
      },
      {
        icon: "🌍",
        title: "Can Access From Anywhere",
        description:
          "Onzup is accessible from any device, allowing users to manage their tasks and responsibilities from anywhere."
      }
    ];


    return (
      <>
        <Navbar />
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.5 }}
        >
          <div data-aos="fade-down" className="service-main ">
      <div className="servicegrid product-Onzup">
        <h1>Product Details</h1>
        <p>Welcome to Beliance Web! Elevate your online presence with our
        expert team and tailored solutions.</p>
      </div>
      </div>

          {/* Web */}
          <div className="margin"></div>

          {/* Web */}
          <div className="margin"></div>
          <div className="web-container grid grid-cols-1 ">
            <div data-aos="fade-in" className="img-web sbm-container">
              <img className="w-100" src={onzup} alt="Web Interface" />
            </div>
            {/* <Lottie style={{width:'90%' , height:'auto'}}  options={defaultOptions} loop='true' isClickToPauseDisabled={true}/> */}
            <div data-aos="fade-up" className="web ml-4">
              <h1> Onzup Software</h1>
              <p className="mt-2">
                Onzup streamlines society management with tools for digital
                billing, expense tracking, and facility booking. It enhances
                communication through announcements and complaint management,
                while also ensuring security with visitor tracking. Accessible
                via a mobile app, Onzup makes housing society operations more
                efficient and user-friendly.
              </p>
              <div className="list-icon grid grid-cols-2 gap-1 mt-4">
                <div className="item item1 flex items-center">
                  <i className="fas fa-circle-check mr-2"></i>Easy to operate
                </div>
                <div className="item item2 flex items-center">
                  <i className="fas fa-circle-check mr-2"></i>Mind blowing UI
                </div>
                <div className="item item3 flex items-center">
                  <i className="fas fa-circle-check mr-2"></i>SMS/Email
                  notifications
                </div>
                <div className="item item4 flex items-center">
                  <i className="fas fa-circle-check mr-2"></i>Can access from
                  anywhere
                </div>
                <div className="item item5 flex items-center">
                  <i className="fas fa-circle-check mr-2"></i>Lot of analytical
                  reports
                </div>
                <div className="item item6 flex items-center">
                  <i className="fas fa-circle-check mr-2"></i>Track day to day
                  expenses
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center space-y-4 mt-4">
            <div className="text-center font-bold text-4xl w-4/5">
              Why Choose Our OnZup Office Management Solution?
            </div>
            <div className="text-center text-lg w-3/5 my-2 product-desc">
              Our Online Office Management Solution simplifies building
              operations by automating routine tasks, enhancing communication,
              and providing real-time access to critical information. From
              managing maintenance requests to tracking expenses, creating
              bills, and fostering seamless communication with society members,
              our solution streamlines the administrative process. Stay
              organized, save time, and improve efficiency—all in one platform!
            </div>
          </div>

          <div className="youtube-video mt-4" data-aos="zoom-in">
            {/* <iframe
            width="50%"
            height="315"
            src="https://youtu.be/EGzw4HjGc3Y?si=UUDl5fNfZS-Gwqf_"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe> */}
            <iframe
            className="youtube-iframe"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/CtoIh8eTSec?si=_AS19f5purx_x4ZH"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>

          <div className="feature-container">
            <h2 className="feature-title">Our Main Features</h2>
            <div className="feature-grid">
              {features.map((feature, index) => (
                <div className="feature-card" key={index}>
                  <div className="feature-icon">{feature.icon}</div>
                  <h3 className="feature-heading">{feature.title}</h3>
                  <p className="feature-description">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="idea">
          <div className="idea-bulb">
            <div className="bulb">
              <i className="fa-regular fa-lightbulb"></i>
            </div>
            <div className="content">
              <h1>Do You have an idea?</h1>
              <p>
              Transform your building management with innovative solutions. 
              Discover how our Smart Building Maintenance Solutions can simplify your operations and enhance efficiency.
              </p>
              <Link className="button" to={"/contact"}>
                Let's talk
              </Link>
            </div>
          </div>
        </div> */}

          <div className="idea py-8 px-4">
            <div className="idea-bulb mx-auto max-w-4xl text-center">
              <div className="bulb mx-auto mb-4">
                <i className="fa-regular fa-lightbulb text-4xl sm:text-5xl text-yellow-500"></i>
              </div>
              <div className="content">
                <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4">
                  Have an Idea for Office Management?
                </h1>
                <p className="text-base sm:text-lg md:text-xl mb-6">
                  Revolutionize your office and building management processes
                  with smart, innovative solutions. See how our{" "}
                  <span className="font-semibold">
                    Online Office Management Solution
                  </span>{" "}
                  can streamline your operations and boost productivity.
                </p>
                <div className="flex flex-col sm:flex-row justify-center gap-4">
                  <Link
                    className="button bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    to="https://www.onzup.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn More
                  </Link>
                  <Link
                    className="button bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} /> */}
          <Footer />
        </motion.div>
      </>
    );
  };

export default Service;
