  import { useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import Footer from './Footer';
import './pages.css';
import { backIn, motion } from 'framer-motion';
import Aos from 'aos';
import 'aos/dist/aos.css';
import dhruvesh from '../images/dhruvesh.jpg'
// import monil from '../images/monil.jpg'


const Team = () => {
  useEffect(() => {
    Aos.init({ duration: 600, offset: 200 });
  }, []);


  const teamMembers = [
    {
      name: 'Dhruvesh Vora',
      position: 'Founder/CEO',
      description: "As the Founder and CEO, I wholeheartedly lead our IT company, weaving vision into reality with creativity, passion, and a deep commitment to innovation and growth.",
      // description: "As the founder, I passionately lead our IT company, shaping its vision into reality with unwavering determination and creativity.",
      image: dhruvesh,
      socials: [

          {
            link:'https://x.com/dhruvesh_vora',
            iconPath: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
          },
          // {
          //   link:'https://github.com/Dhruvesh54',
          //   iconPath: 'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
          // },
          {
            link:'http://linkedin.com/in/dhruvesh-patel54',
            iconPath: "M21.6 0H2.4C1.1 0 0 1.1 0 2.4v19.2C0 22.9 1.1 24 2.4 24h19.2c1.3 0 2.4-1.1 2.4-2.4V2.4C24 1.1 22.9 0 21.6 0zM7.7 19.2H4.8V9.7h2.9v9.5zm-1.4-11c-.9 0-1.6-.7-1.6-1.5s.7-1.5 1.6-1.5c.9 0 1.6.7 1.6 1.5s-.7 1.5-1.6 1.5zm14.3 11h-2.9v-5.1c0-1.2-.4-2-1.5-2-1.6 0-2.3 1.2-2.3 3v4.1h-2.9V9.7h2.8v1.6c.4-.9 1.2-1.8 3-1.8 2.2 0 3.9 1.4 3.9 4.4v5.3z" ,
          },

          {
            link:'https://www.facebook.com/share/gvRwqXUgpKP17EDn/?mibextid=qi2Omg',
            iconPath: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
          },
          {
            link:'https://www.instagram.com/mr.dhruvesh__54?utm_source=qr&igsh=eW9sZGpyaHRyc241',
            iconPath:'M12 2.163c3.204 0 3.584.012 4.85.07 1.17.054 1.796.24 2.213.403.55.213.947.465 1.36.879.413.414.666.81.88 1.36.162.417.35 1.044.403 2.214.058 1.265.07 1.645.07 4.85s-.012 3.584-.07 4.85c-.054 1.17-.24 1.796-.403 2.213-.213.55-.465.947-.879 1.36-.414.413-.81.666-1.36.88-.417.162-1.044.35-2.214.403-1.265.058-1.645.07-4.85.07s-3.584-.012-4.85-.07c-1.17-.054-1.796-.24-2.213-.403a3.835 3.835 0 01-1.36-.879c-.413-.414-.666-.81-.88-1.36-.162-.417-.35-1.044-.403-2.214-.058-1.265-.07-1.645-.07-4.85s.012-3.584.07-4.85c.054-1.17.24-1.796.403-2.213.213-.55.465-.947.879-1.36.414-.413.81-.666 1.36-.88.417-.162 1.044-.35 2.214-.403 1.265-.058 1.645-.07 4.85-.07m0-2.163c-3.259 0-3.67.014-4.947.072-1.276.058-2.157.24-2.905.512a5.92 5.92 0 00-2.142 1.353 5.92 5.92 0 00-1.353 2.142c-.272.748-.454 1.629-.512 2.905-.058 1.277-.072 1.688-.072 4.947s.014 3.67.072 4.947c.058 1.276.24 2.157.512 2.905a5.92 5.92 0 001.353 2.142 5.92 5.92 0 002.142 1.353c.748.272 1.629.454 2.905.512 1.277.058 1.688.072 4.947.072s3.67-.014 4.947-.072c1.276-.058 2.157-.24 2.905-.512a5.92 5.92 0 002.142-1.353 5.92 5.92 0 001.353-2.142c.272-.748.454-1.629.512-2.905.058-1.277.072-1.688.072-4.947s-.014-3.67-.072-4.947c-.058-1.276-.24-2.157-.512-2.905a5.92 5.92 0 00-1.353-2.142 5.92 5.92 0 00-2.142-1.353c-.748-.272-1.629-.454-2.905-.512-1.277-.058-1.688-.072-4.947-.072zm0 5.838a6.142 6.142 0 110 12.284 6.142 6.142 0 010-12.284zm0 10.093a3.951 3.951 0 100-7.902 3.951 3.951 0 000 7.902zm6.406-11.845a1.44 1.44 0 11-2.88 0 1.44 1.44 0 012.88 0z',
          }
      ],
    },
    // {
    //     name: 'Monil Bhuva',
    //     position: 'CEO',
    //     description: "As the CEO of this IT company, I lead with confidence, guiding our team and steering the development of our company.",
    //     image: monil,
    //     socials: [

    //         {
    //             link:'https://x.com/MonilBhuva',
    //           iconPath: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
    //         },
    //         // {
    //         //     link:'https://github.com/Monilbhuva',
    //         //   iconPath: 'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
    //         // },
    //         {
    //             link:'http://linkedin.com/in/monil-bhuva',
    //           iconPath: "M21.6 0H2.4C1.1 0 0 1.1 0 2.4v19.2C0 22.9 1.1 24 2.4 24h19.2c1.3 0 2.4-1.1 2.4-2.4V2.4C24 1.1 22.9 0 21.6 0zM7.7 19.2H4.8V9.7h2.9v9.5zm-1.4-11c-.9 0-1.6-.7-1.6-1.5s.7-1.5 1.6-1.5c.9 0 1.6.7 1.6 1.5s-.7 1.5-1.6 1.5zm14.3 11h-2.9v-5.1c0-1.2-.4-2-1.5-2-1.6 0-2.3 1.2-2.3 3v4.1h-2.9V9.7h2.8v1.6c.4-.9 1.2-1.8 3-1.8 2.2 0 3.9 1.4 3.9 4.4v5.3z" ,
    //         },


    //         {
    //           link:'https://www.facebook.com/monil.monilbhuva',
    //           iconPath: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
    //         },
    //         {
    //           link:'https://www.instagram.com/.its..monil._/',
    //           iconPath:'M12 2.163c3.204 0 3.584.012 4.85.07 1.17.054 1.796.24 2.213.403.55.213.947.465 1.36.879.413.414.666.81.88 1.36.162.417.35 1.044.403 2.214.058 1.265.07 1.645.07 4.85s-.012 3.584-.07 4.85c-.054 1.17-.24 1.796-.403 2.213-.213.55-.465.947-.879 1.36-.414.413-.81.666-1.36.88-.417.162-1.044.35-2.214.403-1.265.058-1.645.07-4.85.07s-3.584-.012-4.85-.07c-1.17-.054-1.796-.24-2.213-.403a3.835 3.835 0 01-1.36-.879c-.413-.414-.666-.81-.88-1.36-.162-.417-.35-1.044-.403-2.214-.058-1.265-.07-1.645-.07-4.85s.012-3.584.07-4.85c.054-1.17.24-1.796.403-2.213.213-.55.465-.947.879-1.36.414-.413.81-.666 1.36-.88.417-.162 1.044-.35 2.214-.403 1.265-.058 1.645-.07 4.85-.07m0-2.163c-3.259 0-3.67.014-4.947.072-1.276.058-2.157.24-2.905.512a5.92 5.92 0 00-2.142 1.353 5.92 5.92 0 00-1.353 2.142c-.272.748-.454 1.629-.512 2.905-.058 1.277-.072 1.688-.072 4.947s.014 3.67.072 4.947c.058 1.276.24 2.157.512 2.905a5.92 5.92 0 001.353 2.142 5.92 5.92 0 002.142 1.353c.748.272 1.629.454 2.905.512 1.277.058 1.688.072 4.947.072s3.67-.014 4.947-.072c1.276-.058 2.157-.24 2.905-.512a5.92 5.92 0 002.142-1.353 5.92 5.92 0 001.353-2.142c.272-.748.454-1.629.512-2.905.058-1.277.072-1.688.072-4.947s-.014-3.67-.072-4.947c-.058-1.276-.24-2.157-.512-2.905a5.92 5.92 0 00-1.353-2.142 5.92 5.92 0 00-2.142-1.353c-.748-.272-1.629-.454-2.905-.512-1.277-.058-1.688-.072-4.947-.072zm0 5.838a6.142 6.142 0 110 12.284 6.142 6.142 0 010-12.284zm0 10.093a3.951 3.951 0 100-7.902 3.951 3.951 0 000 7.902zm6.406-11.845a1.44 1.44 0 11-2.88 0 1.44 1.44 0 012.88 0z',
    //         }
    //     ],
    //   },
    // Add more team members here...
  ];


  return (
    <>
      {/* <Navbar /> */}
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.5 }}
      >
        <div data-aos="fade-down" className="service-main">
          <section className="team ">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py- lg:px-2">
              <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
              <h2 className="mb-4 text-5xl tracking-tight font-bold">Dreamer of Beliance</h2>
                <p className="font-light lg:mb-16 sm:text-xl text-black-900">
                  They exemplify a company or concept that embodies visionary thinking and aspires to achieve significant goals in the digital realm.
                </p>
              </div>  
              <div className="grid gap-8 mb-12 lg:mb-16 md:grid-cols-1 team-one"> 
                {teamMembers.map((member, index) => (
                  <div
                    key={index}
                    className="items-center bg-gray-50 rounded-lg shadow sm:flex bg-gray-700 dark:border-gray-700"
                  >
                    {/* <a>
                      <img className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg" src={member.image} alt={`${member.name} Avatar`} />
                    </a> */}
                    <div className="p-9">
                      <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                        <a>{member.name}</a>
                      </h3>
                      <span className="text-gray-500 dark:text-gray-400">{member.position}</span>
                      <p className="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">{member.description}</p>
                      <ul className="flex space-x-4 sm:mt-0">
                        {member.socials.map((social, i) => (
                          <li key={i}>
                            <a href={social.link} className="text-gray-500 hover:text-gray-900 dark:hover:text-white" target="_blank" alt=''>
                              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path d={social.iconPath} />
                              </svg>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </motion.div>
      {/* <Footer /> */}
    </>
  );
};



export default Team;