export const MenuItem = [
  {
    title : "Home",
    url : '/',
    cName : 'nav-links',
    // icon : 'fa-solid fa-house'
  },

  {
    title : "About Us",
    url : '/about',
    cName : 'nav-links',
    // icon : "fa-solid fa-user"
  },

  {
    title : "Service",
    url : '/service',
    cName : 'nav-links',
    // icon : "fa-solid fa-gear"
  },
  // {
  //   title : "Project",
  //   url : '/project',
  //   cName : 'nav-links',
  //   // icon : "fa-solid fa-briefcase"
  // },
  
  // {
  //   title : "Careers",
  //   url : '/careers',
  //   cName : 'nav-links',
  //   // icon :"fa-solid fa-graduation-cap"
  // },
  {
    title : "Products",
    url : '/products',
    cName : 'nav-links',
    // icon : "fa-solid fa-gear"
  },
  {
    title : "Contact Us",
    url : '/contact',
    cName : 'nav-links',
    // icon : "fa-solid fa-envelope"
  }
]